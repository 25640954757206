    .date-details-invoice {
      display: flex;
      gap: 1rem;
      column-gap: 20.625rem;
      padding-bottom: 0.9375rem;

      margin-top: 10px;
      margin-left: 30px;
    }

    .contract-icon-invoice {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -88px;
      margin-left: -15px;
    }

    .date-details-invoice p {
      display: flex;
      flex-direction: column;
      margin: 0;
    }

    .date-details-invoice p .keyValue {
      margin-top: 0.25rem;
      font-size: 14px;
      color: #73607B;
    }

    .date-details-invoice .effective {
      color: var(--Neutral-1, #3F3F46);
      font-family: Outfit;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .date-details-invoice .keyValue {
      color: var(--Text-h1, #18101C);
      font-family: Outfit;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .line-items-wrapper {
      width: 1171px;
      height: 280px;
      overflow-y: auto;
      flex-shrink: 0;
      margin-top: 20px;
      padding: 16px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #fff;
      margin-left: 30px;
    }

    .line-items-wrapper h3 {
      color: var(--Text-h1, #18101C);
      font-family: Outfit;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: 10px;
    }

    .line-items-wrapper table {
      border-radius: 12px 0px 0px 0px;
      border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
      width: 80%;
      border-collapse: collapse;
      margin-bottom: 16px;
      margin-top: 10px;
      margin-left: 35px;
    }

    .line-items-wrapper table th,
    .line-items-wrapper table td {
      border: 1px solid #ddd;
      text-align: left;
      padding: 8px;
    }

    .line-items-wrapper table th {
      background-color: #f9f9f9;
      font-weight: bold;
    }

    .line-items-wrapper table tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    .line-items-wrapper table tbody tr:hover {
      background-color: #f1f1f1;
    }


    .line-items-wrapper button:hover {
      background-color: #ddd;
    }

    .line-items-wrapper button:active {
      background-color: #ccc;
    }

    .line-items-wrapper button.add-item {
      background-color: #4caf50;
      color: white;
      font-weight: bold;
    }

    .line-items-wrapper button.add-item:hover {
      background-color: #45a049;
    }

    .line-items-wrapper button.add-item:active {
      background-color: #3e8e41;
    }

    .line-items-wrapper button.edit {
      background-color: #2196f3;
      color: white;
    }

    .line-items-wrapper button.edit:hover {
      background-color: #1976d2;
    }

    .line-items-wrapper button.delete {
      background-color: #f44336;
      color: white;
    }

    .line-items-wrapper button.delete:hover {
      background-color: #d32f2f;
    }

    .payment-informations {
      display: flex;
    }

    .add-item-invoice {
      display: inline-flex;
      padding: 5px 12px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
      background: var(--Background-main, #FEF9F7);
      color: var(--Primary-500, #C95EBE);
      font-family: Outfit;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 33px;
    }