.AI-CP-full-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-family: "Outfit", sans-serif;
  /* border: 2px solid red; */
  margin-left: 0;
  margin-top: 0px;
  width: 100%;
  /* background: rgb(232,192,255); */
  background: linear-gradient(111deg, #F5EEFF 14.52%, #FDFDFD 99.31%);
  overflow: hidden;
}



.AI-CP-docChatContainer{
  /* border: 2px solid red; */
  width: 1250px;
  background:transparent;
  /* margin-left: 150px; */
  margin-top: 10px;
  height: 80%;
}
.AI-CP-docChatContent{
  /* border: 2px solid green; */
  width: 90%;
  background:transparent;
  margin-left: 150px;
  /* margin-top: 50px; */
  height: 90%;
}

.reaction-icon {
  position: relative;
  display: inline-block;
}

.charge-icon {
  width: 24px; 
  height: 24px;
}

.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.75); 
  color: #fff; 
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: 'Outfit', sans-serif;
  position: absolute;
  bottom: 150%; 
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.disabled-button-thumsdown {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  opacity: 0.6;
}

.enabled-button-thumsdown {
  background-color: purple;
  color: white;
  cursor: pointer;
  opacity: 1;
}


.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.reaction-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.page-counter {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0.625rem;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  color: #666;
}

.current-page {
  font-weight: bold;
  color: #333;
}

.pagination-buttons {
  display: flex;
  gap: 0.625rem;
}

.nav-button {
  background: none;
  border: none;
  font-size: 1.125rem;
  color: #a64ace;
  cursor: pointer;
}

.document-name {
  font-size: 0.875rem;
  color: #333;
  font-weight: 500;
}

.zoom-controls {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  color: #333;
}

.zoom-level {
  font-size: 0.875rem;
  color: #333;
}

.AI-CP-message-container {
  height: 90%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #b0b0b0 #dbdbdb;
}

.message-bubble {
  background-color: #e0e0e0;
  padding: 0.9375rem;
  border-radius: 0.625rem;
  display: inline-block;
  margin-bottom: 0.625rem;
  color: #333;
}

.message-bubble p {
  margin: 0;
}



.outline-button {
  border: 1px solid #c3b4d1;
  border-radius: 0.9375rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  background: transparent;
  cursor: pointer;
  text-align: left;
  width: 100%;
}



.AI-CP-send-button-holde {
  background-color: #a64ace;
  color: white;
  border: none;
  border-radius: 50%;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
}

.AI-CP-bot-message-container {
  font-family: Outfit;
  display: flex;
  align-items: flex-end; 
  margin-bottom: 1rem; 
}

.bot-icon-container {
  width: 2rem;
  height: 2rem;
  margin-right: 0.825rem; 
  flex-shrink: 0; 
  align-self: flex-end;
}

.AI-CP-bot-icon {
  width: 94%;
  height: 94%;
  border-radius: 50%;
  margin-top: -106px;
}
.AI-CP-bot-icon-thinking{
  width: 94%;
  height: 94%;
  border-radius: 50%;
/* margin-top: 0px; */
}

.bot-message-bubble {
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  background: #e9e9eb;
  display: flex;
  padding: 1rem 2.3125rem 0.9375rem 1.25rem;
  align-items: center;
  align-self: stretch;
  position: relative;
  color: var(--Neutral-1, #3f3f46);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.625rem;
}

.bot-message-bubble p {
  margin: 0;
  word-wrap: break-word; 
}
.AI-CP-bot-message-content {
  display: inline-block;
  justify-content: center;
  align-self: stretch;
  border-radius: 16px 16px 16px 0px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: #E9E9EB;  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 16px 20px;
  width: 899px ;
   word-wrap: break-word;
  margin-top: 24px;
  flex-shrink: 0;
  max-width: 867px; 
  white-space: pre-wrap; /* Ensures line breaks are respected */
  overflow: visible; /* Allow content to flow without scrollbars */
  margin-right: 15px;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);

}

.AI-CP-CopilotChatHistoryContainer{
   /* border: 1px solid red; */
   left: 250px;
   position: absolute;
   top: 50px;
}



.AI-CP-user-message-bubble {
  display: inline-flex; 
  padding: 0.75rem 0.875rem;
  justify-content: center;
  align-items: center;
  border-radius:20px 20px 0px 20px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--Primary-500, #C95EBE);
  color: var(--Bg-Color, #faf9f6);
  font-family: 'Outfit', sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 0.625rem;
  width: fit-content;
  min-width: 4rem;
  max-width: 859px;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

}





.AI-CP-reactionContainer{
  display: flex ;
 align-items: flex-end;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Ai-CP-reactions {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.5rem;
}

.AI-CP-reaction-buttons{
       display: flex ;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-right: 20px;
}
.AI-CP-reaction-buttons>button{
  padding-top: 6px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 6px;
}

.reaction-icon {
  cursor: pointer;
}
textarea::placeholder {
 
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

  .AI-CP-dummy-container{
    display: inline-flex;
    /* border: 2px solid green; */
    gap: 10px;
    align-items: self-start;
    justify-content: flex-start;
    margin-top:80px;
    margin-left: 40px;
  
  }

.AI-CP-dummy-message-box {
  display: flex;
  /* gap: 20px; */
 
  background: rgba(255, 255, 255, 1);
  margin-top: 0px;
  width: auto;
  height: 37px;
  border-radius: 0.375rem;
  box-shadow: 176px 52px 51px 0px rgba(184, 86, 174, 0.00), 113px 33px 47px 0px rgba(184, 86, 174, 0.01), 63px 19px 40px 0px rgba(184, 86, 174, 0.05), 28px 8px 29px 0px rgba(184, 86, 174, 0.09), 7px 2px 16px 0px rgba(184, 86, 174, 0.10);

  /* border: 1px solid var(--new-stroke, #c95ebe); */
}

.text-sm {
  color: rgba(63, 63, 70, 0.74);
  font-family: Outfit;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.spinner {
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-top: 0.25rem solid #3498db;
  border-right: 0.25rem solid #8e44ad;
  border-radius: 50%;
  animation: spin 0.8s cubic-bezier(0.5, 0.2, 0.3, 1) infinite;
  box-shadow: 0 0 0.5rem rgba(52, 152, 219, 0.6);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-container-thumsup {
  position: fixed;
  top: 60%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  width: 391px;
height: 111px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.popup-container-thumsup {
  position: fixed;
  top: 60%;
  left: 30%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 391px;
height: 111px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.popup-content-thumsup {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thums-complete {
  width: 40px;
  height: 40px;
  margin-left: 150px;
  margin-top: -15px;
}

.close-button-thumsup {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  margin-left: 10px;
  margin-top: -20px;
}

.popup-message {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  color: var(--Text-h2, #171717);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.26px;
}

.popup-container-thumsdown {
  position: fixed;
  top: 20%;
  left: 25%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 438px;
height: 372px;
flex-shrink: 0;
border-radius: 12px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.popup-content-thumsdown {
  padding: 20px;
  border-radius: 8px;
  
  
}

.popup-header {
  display: flex;
  justify-content: space-between;
 
}

.close-button-thumsdown {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding-left: 390px;
  padding-top: 170px;
}
.paragraph-container{
  margin-bottom: 180px;
}
.popup-message {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

.reaction-icon img.filled {
  fill: red; 
}

.paragraph-container-para1{
  color: var(--Primari_300, #BB42AE);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
height: 19px;
align-self: stretch;
margin-right: 240px;
}
.paragraph-container-para2 {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: stretch;

  white-space: normal; 

  word-wrap: break-word;

  line-height: 1.5; 
  margin-top: 10px;
}
.checkbox-container-thumsup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px; 
  width: 213px; 
}

.thumsup-checkbox {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
  gap: 10px;
}
.thumbsup1{
  width: 40px;
  height: 40px;
}
.checkbox-container-thumsup input[type="checkbox"] {
  width: 16px;
  height: 16px; 
  margin-right: 8px;
  margin-top: 5px;
}
.line-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%; 
  margin: 10px 0;
}

.line-with-text::before,
.line-with-text::after {
  content: '';
  flex-grow: 1;
  border-top: 1px solid #73607B;
}

.AI-CP-thinking-indicator {
  display: flex;
  gap: 5px; 
  align-items: center;
  margin-top: 30px;
}

.AI-CP-dot {
  width: 8px;
  height: 8px;
  background-color: rgb(231, 106, 220);
  border-radius: 50%;
  animation: AI-CP-thinking-dots 1.2s infinite ease-in-out;
}


@keyframes AI-CP-thinking-dots {
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.5; 
  }
  40% {
    transform: scale(1.4); 
    opacity: 1; 
  }
}


.line-with-text span {
  font-family: Outfit, sans-serif;
  font-size: 13px;
  color: #3f3f46; 
  margin: 0 10px; 
}
.rectangular-box {
  width: 390px; 
  height: 83px; 
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--Background-main, #FEF9F7); 
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  padding: 12px; 
  box-sizing: border-box;
  margin-top: -12px; 
}

.feedback-text {
  color: rgba(82, 82, 91, 0.72); 
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.feedback-input {
  width: 100%;
  height: 50px;
  padding: 2px;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  resize: none;
}

.character-count {
  color: rgba(115, 96, 123, 0.80);
  font-family: Outfit;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.custom-button-thumsdown {
  display: inline-flex;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 270px;
  margin-top: 3px;
  border-radius: 6px;
  border: 1px solid var(--Primary-500, #C95EBE);
  background: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button-thumsdown:hover {
  background-color: rgba(201, 94, 190, 0.1); 
}

.custom-button-thumsdown {
  color:#fff;
  background-color: #C95EBE;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.AI-CP-input-container-holder {

  max-height: 80px ; 
  min-height: 60px;
  overflow-y: hidden;
  flex-shrink: 0;
  border-radius: 20px; /* Smooth rounded edges */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
  background: #fff;
  display: flex;
  align-items: flex-start;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10; /* Ensure it stays above other content */
  width: 1020px; 
}

.AI-CP-input-field-holder {
  width: 85%;
  min-height: 22px;
  resize: none;
  color: #3f3f46;
  /* border: 1px solid red !important; */
  font-family: 'Outfit', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-left: 35px;
  margin-top: 17px;
  border: none;
  outline: none;
  background-color: transparent;
  overflow: hidden;
  max-height: 80px;
  overflow-y: auto;
}

.AI-CP-send-button-holder {
  

  transition: all 0.3s ease; /* Smooth hover effect */
  position: absolute; /* Fixes the button relative to the viewport */
  bottom: 4px; /* Adjusts the distance from the bottom */
  right: 15px;
cursor: pointer;
}

.AI-CP-send-button-holder:hover {
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); Subtle shadow */
}
.AI-CP-send-button-holder:disabled {
  cursor: not-allowed; /* Disabled cursor */
  /* border-radius: 10px; */
}


@media (min-width: 1024px) and (max-width: 1280px) {
  .AI-CP-dummy-container{
    /* margin-bottom: 1rem; */
    /* gap: 1rem; */
  }
  /* .dummy-message-box {
    width: 90%;
    max-width: 35rem;
    min-width: 20rem;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
  } */

  .AIcustom-input-wrapper .input-container-holder {
    max-width: 600px;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  /* .dummy-message-box {
    width: 90%;
    max-width: 35rem;
    min-width: 20rem;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
  } */

  .AI-CP-dummy-container {
    margin-bottom: 10px;
    gap: 10px;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
  }

  .left-side {
    width: 70%;
    max-width: 100%;
  }

  .right-side {
    width: 50%;
    max-width: 70%;
    margin-right: 1vw;
  }

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 90%;
    margin-left: 5%;
  }

  .AIcustom-input-wrapper .input-container-holder {
    max-width: 600px;
  }
}


@media (min-width: 1440px) and (max-width: 1536px) {
  /* .dummy-message-box {
    width: 90%;
    max-width: 35rem;
    min-width: 20rem;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
  } */

  .AI-CP-dummy-container {
    margin-top: 2rem;
    gap: 1rem;
  }

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 92%;
    margin-left: 5%;
  }

  .AIcustom-input-wrapper .input-container-holder {
    max-width: 600px;
  }

  .AI-CP-dummy-container {
    margin-left: -0.5rem;
  }
}


@media (min-width: 1536px) and (max-width: 1920px) {
  /* .dummy-message-box {
    width: calc(74% + 5rem);
    max-width: 90%;
    margin: 0.5rem auto;
    margin-bottom: 10px;
    padding: 1.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  } */

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 88%;
    margin-left: 5%;
  }

  .AIcustom-input-wrapper .input-container-holder {
    max-width: 750px;
  }

  .AI-CP-dummy-container {
    margin-left: 11px;
    display: flex;
    /* flex-direction: column; */
    margin-bottom: 20px;
  }
}


@media (min-width: 1920px) and (max-width: 2560px) {
  /* .dummy-message-box {
    width: calc(78% + 5rem);
    max-width: 90%;
    margin: 0.5rem auto;
    padding: 1.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: sticky;
    top: 2rem;
    z-index: 10;
  } */

  .AI-CP-dummy-container {
    margin-top: 1rem;
    gap: 0.5rem;
    /* margin-left: -50px; */
    position: sticky;
    top: 4rem;
    z-index: 5;
  }

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 92%;
    margin-left: 10px;
    position: sticky;
    top: 6rem;
    z-index: 5;
  }

  .AIcustom-input-wrapper .input-container-holder {
    max-width: 1050px;
    position: sticky;
    top: 6rem;
    z-index: 5;
  }
}