.full-container-chat {
  display: flex;
  flex-direction: column;
  height: 88vh;
  font-family: "Outfit", sans-serif;
}

.content-container {
  display: flex;
  flex-grow: 1;
}
.reaction-icon {
  position: relative;
  display: inline-block;
}

.charge-icon {
  width: 24px; 
  height: 24px;
}

.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.75); 
  color: #fff; 
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: 'Outfit', sans-serif;
  position: absolute;
  bottom: 150%; 
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.disabled-button-thumsdown {
  background-color: grey;
  color: white;
  cursor: not-allowed;
  opacity: 0.6;
}

.enabled-button-thumsdown {
  background-color: purple;
  color: white;
  cursor: pointer;
  opacity: 1;
}


.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.reaction-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.left-side {
  width: 54%;
  height: 88vh;
  padding: 1.25rem;
  margin-left: -0.875rem;
  background: var(--Background-main, #FEF9F7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iframe-container {
  background-color: white;
  border: 1px solid #ddd;
}

.right-side {
  width: 46%;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-left: 1.125rem;
  justify-content: space-between;
  height: 88vh;
  background-color: white;
}
.highlighted-section {
  background-color: rgba(255, 255, 0, 0.5);
  position: absolute;
  z-index: 2;
}


.document-navigation {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: -37.5rem;
}

.page-counter {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 0.625rem;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  color: #666;
}

.current-page {
  font-weight: bold;
  color: #333;
}

.pagination-buttons {
  display: flex;
  gap: 0.625rem;
}

.nav-button {
  background: none;
  border: none;
  font-size: 1.125rem;
  color: #a64ace;
  cursor: pointer;
}

.document-name {
  font-size: 0.875rem;
  color: #333;
  font-weight: 500;
}

.zoom-controls {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  color: #333;
}

.zoom-level {
  font-size: 0.875rem;
  color: #333;
}

.message-container {
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
  overflow-y: auto; 
  height: calc(100vh - 150px); 
}


.message-container::-webkit-scrollbar {
  width: 8px; 
}

.message-container::-webkit-scrollbar-track {
  background: #FAF9F6; 
}

.message-container::-webkit-scrollbar-thumb {
  background: #DBDBDB; 
  border-radius: 10px; 
}







.user-message-bubble,
.bot-message-content {
  margin-top: 0; /* Reset margin to align vertically */
}

.bot-message-container,
.user-message-container {
  display: flex;
  align-items: flex-start; /* Align content at the top */
}


.user-message-bubble,
.bot-message-content {
  margin-top: 0.5rem; /* Space between messages */
  margin-bottom: 0.5rem; /* Ensure equal spacing */
}


.user-message-bubble {
  background-color: var(--Primary-500, #c95ebe);
  color: var(--Bg-Color, #faf9f6);
  font-family: 'Outfit', sans-serif;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: normal;
  max-width: 642px;
  margin-right: auto; /* Push the message to the left */
  margin-top: 0; /* Ensure alignment with other messages */
  border-radius: 0.75rem 0.75rem 0.75rem 0; /* Optional: Custom bubble shape */
  padding: 0.75rem 1rem; /* Adjust padding for better spacing */
  word-wrap: break-word; /* Ensure long words break properly */
}



.message-bubble {
  background-color: #e0e0e0;
  padding: 0.9375rem;
  border-radius: 0.625rem;
  display: inline-block;
  margin-bottom: 0.625rem;
  color: #333;
}

.message-bubble p {
  margin: 0;
}

.icon-row {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.3125rem;
  color: #666;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.outline-button {
  border: 1px solid #c3b4d1;
  border-radius: 0.9375rem;
  padding: 0.625rem;
  font-size: 0.875rem;
  background: transparent;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 1.5625rem;
  padding: 0.625rem;
}

.input-container input {
  flex-grow: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 0 0.625rem;
  font-size: 0.875rem;
}

.send-button {
  background-color: #a64ace;
  color: white;
  border: none;
  border-radius: 50%;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
}

.bot-message-container {
  font-family: Outfit;
  display: flex;
  align-items: flex-end; 
  margin-bottom: 1rem; 
}

.bot-icon-container {
  width: 2rem;
  height: 2rem;
  margin-right: 0.825rem; 
  flex-shrink: 0; 
  align-self: flex-end;
}

.bot-icon {
  width: 94%;
  height: 94%;
  border-radius: 50%;
  margin-top: -35px;
}

.bot-message-bubble {
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  background: #e9e9eb;
  display: flex;
  padding: 1rem 2.3125rem 0.9375rem 1.25rem;
  align-items: center;
  align-self: stretch;
  position: relative;
  color: var(--Neutral-1, #3f3f46);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.625rem;
}

.bot-message-bubble p {
  margin: 0;
  word-wrap: break-word; 
}
.bot-message-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px 12px 12px 0px;
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  padding: 16px 20px;
  min-width: 4rem;
  word-wrap: break-word;
  background: #e9e9eb;
  margin-top: 24px;
  flex-shrink: 0;
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;

  max-width: 642px; 
  overflow-y: auto;   
  white-space: pre-wrap;
}

.user-message-bubble {
  display: inline-flex; 
  padding: 0.75rem 0.875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem 0.75rem 0.75rem 0;
  background: var(--Primary-500, #c95ebe);
  color: var(--Bg-Color, #faf9f6);
  font-family: 'Outfit', sans-serif;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 0.125rem;
  width: fit-content;
  min-width: 4rem;
  max-width: 642px; 
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: -1px;

}


.bot-message-content {
  display: block;
  margin-bottom: 0.5rem;
}

.reactions {
  display: flex;
  gap: 0.625rem;
  margin-top: 0.5rem;
}

.reaction-icon {
  cursor: pointer;
}
textarea::placeholder {
 
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
}

.dummy-container{
  margin-left: -15px;
}



  .dummy-message-box {
    display: inline-flex;
    padding: 6px 16px;
    align-items: center;
    gap: 0.15rem;
    align-items: center;
    margin-left: 1.1rem;
    min-width: 20px;
    max-width: fit-content; 
    min-height: 33px;
    max-height: 100px;
    border-radius: 0.375rem;
    background: #FFF;
    border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    box-shadow: 194px 160px 71px 0px rgba(236, 93, 238, 0.00), 
                124px 103px 65px 0px rgba(236, 93, 238, 0.01), 
                70px 58px 54px 0px rgba(236, 93, 238, 0.05), 
                31px 26px 40px 0px rgba(236, 93, 238, 0.09), 
                8px 6px 22px 0px rgba(236, 93, 238, 0.10);
                white-space: normal; 
                word-break: break-word;
  }
  .input-container-holder {
    position: relative; 
  }

.text-sm {
  color: rgba(63, 63, 70, 0.74);
  font-family: Outfit;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-input-container {
  display: flex;
  align-items: flex-start;
  padding: 0.625rem 0.9375rem;
  margin-left: 1.25rem;
  width: 739px;
  min-height: 52px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 2.5rem;
  border: 1px solid rgba(187, 66, 174, 0.36);
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.custom-input-container.expanded {
  height: auto; 
  max-height: 88px;
}
.reaction-icon {
  position: relative;
  display: inline-block;
}

.reaction-tooltip {
  fill: var(--Background-main, #FEF9F7);
stroke-width: 1px;
stroke: var(--black_stroke, rgba(0, 0, 0, 0.10));
border: 1px solid grey;
  position: absolute;
  bottom: -23px; /* Adjust for proper placement */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 1px 3px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
}

.reaction-icon:hover .reaction-tooltip {
  opacity: 1;
  visibility: visible;
}

.custom-input-field-contract {
  flex-grow: 1;
  border: none !important;
  outline: none !important;
  background: transparent;
  color: var(--Neutral-1, #3f3f46);
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  resize: none;
  width: 100%;
  min-height: 20px;
  max-height: none; 
  overflow-y: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-top: 3px;
}

.custom-input-field::placeholder {
  color: rgba(115, 96, 123, 0.72);
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}
.custom-input-field:-webkit-autofill,
.custom-input-field:-webkit-autofill:hover,
.custom-input-field:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset; 
  border: none;
}

.custom-input-field:focus {
  border: 1px solid #a64ace; 
  outline: none; 
}

.custom-input-container.expanded .custom-input-field {
  overflow-y: scroll; 
}

.input-divider {
  width: 0.0625rem;
  height: 1.5rem;
  background-color: #ccc;
  margin: 0 0.625rem;
}

.custom-send-button {
  background-color: #d1a3d3;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}



.spinner {
  width: 2rem;
  height: 2rem;
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-top: 0.25rem solid #3498db;
  border-right: 0.25rem solid #8e44ad;
  border-radius: 50%;
  animation: spin 0.8s cubic-bezier(0.5, 0.2, 0.3, 1) infinite;
  box-shadow: 0 0 0.5rem rgba(52, 152, 219, 0.6);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-container-thumsup {
  position: fixed;
  top: 60%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  width: 391px;
height: 111px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.popup-container-thumsup {
  position: fixed;
  top: 60%;
  left: 30%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 391px;
height: 111px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.popup-content-thumsup {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thums-complete {
  width: 40px;
  height: 40px;
  margin-left: 150px;
  margin-top: -15px;
}

.close-button-thumsup {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  margin-left: 10px;
  margin-top: -20px;
}
.highlight-frame {
  border: 3px solid #ffcc00; /* Highlight color */
  box-shadow: 0 0 15px rgba(255, 204, 0, 0.75);
}


.popup-message {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  color: var(--Text-h2, #171717);
text-align: center;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.26px;
}

.popup-container-thumsdown {
  position: fixed;
  top: 20%;
  left: 25%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 438px;
height: 372px;
flex-shrink: 0;
border-radius: 12px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.popup-content-thumsdown {
  padding: 20px;
  border-radius: 8px;
  
  
}

.popup-header {
  display: flex;
  justify-content: space-between;
 
}

.close-button-thumsdown {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding-left: 390px;
  padding-top: 170px;
}
.paragraph-container{
  margin-bottom: 180px;
}
.popup-message {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

.reaction-icon img.filled {
  fill: red; 
}

.paragraph-container-para1{
  color: var(--Primari_300, #BB42AE);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
height: 19px;
align-self: stretch;
margin-right: 240px;
}
.paragraph-container-para2 {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: stretch;

  white-space: normal; 

  word-wrap: break-word;

  line-height: 1.5; 
  margin-top: 10px;
}
.checkbox-container-thumsup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px; 
  width: 213px; 
}

.thumsup-checkbox {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 8px;
  gap: 10px;
}
.thumbsup1{
  width: 40px;
  height: 40px;
}
.checkbox-container-thumsup input[type="checkbox"] {
  width: 16px;
  height: 16px; 
  margin-right: 8px;
  margin-top: 5px;
}
.line-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%; 
  margin: 10px 0;
}

.line-with-text::before,
.line-with-text::after {
  content: '';
  flex-grow: 1;
  border-top: 1px solid #73607B;
}

.thinking-indicator {
  display: flex;
  gap: 5px; 
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #BB42AE;
  border-radius: 50%; 
  animation: thinking-dots 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s; 
}

.dot:nth-child(2) {
  animation-delay: 0.2s; 
}

.dot:nth-child(3) {
  animation-delay: 0.4s; 
}

@keyframes thinking-dots {
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.5; 
  }
  40% {
    transform: scale(1.4); 
    opacity: 1; 
  }
}


.line-with-text span {
  font-family: Outfit, sans-serif;
  font-size: 13px;
  color: #3f3f46; 
  margin: 0 10px; 
}
.rectangular-box {
  width: 390px; 
  height: 83px; 
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--Background-main, #FEF9F7); 
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  padding: 12px; 
  box-sizing: border-box;
  margin-top: -12px; 
}

.feedback-text {
  color: rgba(82, 82, 91, 0.72); 
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.feedback-input {
  width: 100%;
  height: 50px;
  padding: 2px;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  resize: none;
}

.character-count {
  color: rgba(115, 96, 123, 0.80);
  font-family: Outfit;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.custom-button-thumsdown {
  display: inline-flex;
  padding: 8px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 270px;
  margin-top: 3px;
  border-radius: 6px;
  border: 1px solid var(--Primary-500, #C95EBE);
  background: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button-thumsdown:hover {
  background-color: rgba(201, 94, 190, 0.1); 
}

.custom-button-thumsdown {
  color:#fff;
  background-color: #C95EBE;
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}

.input-container-holder {
  margin-left: 20px;
  width: 663px;
  min-height: 55px;
  height: auto;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid rgba(187, 66, 174, 0.36);
  background: #fff;
  display: flex;
  align-items: flex-start;
  margin-bottom: -27px;
}




.custom-input-wrapper .input-container-holder .input-field-holder {
  max-height: 88px; 
  overflow-y: auto; 
  font-size: 16px;
  
}
.source-panel {
  max-height: 100%;
  overflow-y: auto;
  background-color: #f9f9f9;
  padding: 16px;
}

.source-panel h3 {
  color: #333;
}

.source-panel p {
  margin-bottom: 8px;
}

.source-panel a {
  color: #007bff;
  text-decoration: underline;
}




.input-container-holder .send-button-holder {
  position: absolute;  /* Fixes the button relative to the viewport */
  bottom: 10px; /* Adjust as needed to control vertical placement */
  right: 10px;
  border: none; 
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Primary-500, #C95EBE);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.input-container-holder .send-button-holder img {
  width: 24px;
  height: 24px;
}
@media (min-width: 1024px) and (max-width: 1280px) {
 
 
  
.left-side {
  width: 50%;
  max-width: 100%; 
}
.dummy-container{
  margin-bottom: 40px;
}

.right-side {
  width: 50%; 
  max-width: 70%; 
  margin-right: 1vw;
}

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 0rem;
  }

  .custom-input-wrapper .input-container-holder {
    max-width: 600px;
  }
}
@media (min-width: 1280px) and (max-width: 1440px) {
  .dummy-container{
    margin-bottom: 5px;
  }


  .left-side {
    width: 70%;
    max-width: 100%;
  }

  .right-side {
    width: 50%;
    max-width: 70%;
    margin-right: 1vw;
  }

  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 90%;
    margin-left: 5%;
  }

  .custom-input-wrapper .input-container-holder {
    max-width: 600px;
  }
}


@media (min-width: 1440px) and (max-width: 1536px) {
 
  .dummy-container{
    margin-bottom: 20px;
  }


  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 92%;
    margin-left: 5%;
  }

  .custom-input-wrapper .input-container-holder {
    max-width: 600px;
  }


}


@media (min-width: 1536px) and (max-width: 1920px) {
  
  .dummy-container{
    margin-bottom: 10px;
  }
  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {

    width: 88%;
    margin-left: 5%;
  }

  .custom-input-wrapper .input-container-holder {
    max-width: 750px;
  }

}


@media (min-width: 1920px) and (max-width: 2560px) {
 
  .dummy-container{
    margin-bottom: 30px;
  }


  .custom-input-field-contract {
    font-size: 0.875rem;
  }

  .input-container-holder {
    width: 92%;
    margin-left: 10px;
    position: sticky;
    top: 6rem;
    z-index: 5;
  }

  .custom-input-wrapper .input-container-holder {
    max-width: 1050px;
    position: sticky;
    top: 6rem;
    z-index: 5;
  }
}



