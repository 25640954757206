.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .upload-area.drag-over {
    border: 2px dashed #8a2be2;
    background-color: #f0f0f0;
  }
  
  .selected-file-folder{
    margin-left: 270px;
  }
  .modal-container {
    position: relative;
    width: 936px;
height: 432px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.20);
background: #FFF;
box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
  }
  
  .modal-close {
    position: absolute;
    top: 10px; 
    right: 16px;
    background: none;
    color: black;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 10;
  }
  

  .cross-mark {
    display: flex;
    margin-top: 12px;
    margin-right: 20px;
    justify-content: flex-end; 
    width: 100%; 
  }


  
  .upload-small-folder {
    max-width: none;
    width: 1.175rem;
    height: 1.175rem;
    margin-left: 1.8rem;
    margin-top: -1rem;
    position: static; 
}

  
 .title-name{
    display: flex;
 } 
  .modal-title {
    
    color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 24px;
margin-left: 44px;
  }
  
  .modal-description {
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 11px;
margin-left: 44px;
  }
  
  .input-group {
    text-align: left;
    margin-left: 44px;
    width: 450px;
height: 44px;
flex-shrink: 0;
margin-top: 21px;

  }
  
  .input-group label {
    color: var(--Neutral-1, #3F3F46);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;

  }
  
  .input-group input {
    width: 100%;
    padding: 8px;
    color: rgba(115, 96, 123, 0.60);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  }
  
  .upload-area {
   margin-top: 66px;
   width: 864px;
height: 153px;
flex-shrink: 0;
border-radius: 16px;
margin-left: 44px;
text-decoration: none;
border: 1px dashed var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
  }
  
  .upload-area .choose-file {
    color: var(--Text-h2, #171717);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
    cursor: pointer;
    text-decoration: none;
    margin-left: 290px;
    margin-top: 20.81px;
  }
  .choose-file-span{
    color: var(--Primari_300, #BB42AE);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
  }
  .uploaded-file-name {
    font-size: 14px;
    color: #333;
  }
  
  .progress-area {
    margin-top: 66px;
   width: 864px;
height: 153px;
flex-shrink: 0;
border-radius: 16px;
margin-left: 44px;
text-decoration: none;
border: 1px dashed var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
  }
  
  .progress-bar {
    width: 795px;
height: 12px;
flex-shrink: 0;
border-radius: 16px;
background: #D9D9D9;
    margin-top: 5px;
    margin-left: 34px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background: #C95EBE;
    width: 0;
    border-radius: 5px;
    transition: width 0.4s ease;
  }
  .progress-percentage{
    color: var(--Primari_300, #BB42AE);
    width: auto;
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
align-self: stretch;
margin-left: 40px;
white-space: nowrap;
margin-top: 10px;
  }
  .progress-message{
    color: var(--Neutral-300, #3F3F46);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: -94px;
  }
  .modal-button {
    display: inline-flex;
padding: 8px 32px;
justify-content: center;
align-items: center;
gap: 8px;
    cursor: pointer;
    border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
background: var(--Primary-500, #C95EBE);
color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); /* 171.429% */
letter-spacing: 0.14px;
margin-left: 748px;
margin-top: 20px;
  }
  .file-icon-folder{
    width: 51.193px;
height: 51.193px;
flex-shrink: 0;
margin-left: 390px;
margin-top: 28px;
position: relative;
  }

  .upload-icon-folder{
    width: 19.273px;
height: 19.273px;
flex-shrink: 0;
margin-left: 395px;
position: absolute; 
top: 416px; 
left: 370px;
  }

  .uploaded-Success-Area-Doc{
    margin-top: 70px;
    margin-left: 36px;
    width: 864px;
height: 239px;
flex-shrink: 0;
border-radius: 16px;
border: 1px dashed var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
  }
  .successIconDoc{
    width: 25.884px;
height: 25.89px;
flex-shrink: 0;
margin-left: 404.23px;
margin-top: 32.23;
  }
  .modal-title-doc-success{
    color: var(--Neutral-500, #18181B);
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 25.83px;
margin-left: 305px;
  }
  .success-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: 27px;
    margin-top: 15px;
  }
  
  .success-details img {
    width: 24px;
    height: 24px;
  }
  .success-details p{
    color: var(--Neutral-300, #3F3F46);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .doc-time-estimate {
    
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
    margin-right: 50px;
  }
  .Uploading-icon-doc{
    margin-left: 414px;
    margin-top:8px;
  }

  .doc-uploading-para-one{
    margin-top: 5.7px;
    margin-left: 344px;
  }
  .progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    gap: 10px;
    font-size: 14px;
    margin-top: -15px; 
  }

  .doc-uploading-para-two{
    color: var(--text_non_active, #73607B);
    width: 200px;
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
align-self: stretch;
margin-left: 34px;
  }

  .modal-description-doc-success{
    color: var(--Neutral-300, #3F3F46);
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 11px;
margin-left: 256px;
  }
.modal-button-final-success{
  display: inline-flex;
padding: 12px 36px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
background: var(--Primary-500, #C95EBE);
color: var(--white-100, #EAF7FF);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: var(--Typography-Line-Height-300, 24px); 
letter-spacing: 0.16px;
margin-left: 403.5px;
margin-top: 50px;
}

@media (min-width: 1024px) and (max-width: 1280px){
  .modal-container {  
  width: 766px;
  height: 332px;
  }
  .upload-area {
    margin-top: 24px;
    width: 634px;
 height: 103px;
 flex-shrink: 0;
 border-radius: 16px;
 margin-left: 44px;

   }
   .file-icon-folder{
    width: 31.193px;
height: 31.193px;
margin-left: 300px;
margin-top: 18px;

}
.upload-area .choose-file {
font-size: 15px;
font-size: 14px;
  margin-left: 210px;
  margin-top: 24.81px;
}
.modal-button{
  width: 145px;
  height: 30px;
  margin-left: 520px;
  font-size: 12px;
}

.uploaded-Success-Area-Doc{
  width: 700px ;
  height: 180px;
}
.modal-title-doc-success{
  
font-size: 16px;
margin-top: 22.83px;
margin-left: 245px;
}
.modal-description-doc-success{
font-size: 14px;
margin-top: 11px;
margin-left: 186px;
}
.success-details {
  margin-left: -10px;
  margin-top: 15px;
}
.modal-button-final-success{
  display: inline-flex;
padding: 10px 25px;
justify-content: center;
align-items: center;
font-size: 12px;
margin-left: 343.5px;
margin-top: 20px;
}

.Uploading-icon-doc{
  margin-left: 414px;
  margin-top:8px;
}
.doc-uploading-para-one{
  margin-top: 5.7px;
  margin-left: 344px;
}
.progress-area {
  margin-top: 26px;
 width: 634px;
height: 133px;
}
.progress-percentage{
  font-size: 14px;
}
.Uploading-icon-doc{
  margin-left: 314px;
  margin-top:6px;
}
.progress-bar {
  width: 555px;
height: 12px;
  margin-top: 5px;
  margin-left: 34px;
}
.doc-uploading-para-one{
  margin-left: 250px;
}


}
@media (min-width: 1280px) and (max-width: 1440px){
  .modal-container {  
  width: 766px;
  height: 332px;
  }
  .upload-area {
    margin-top: 24px;
    width: 604px;
 height: 103px;
 flex-shrink: 0;
 border-radius: 16px;
 margin-left: 44px;

   }
   .file-icon-folder{
    width: 31.193px;
height: 31.193px;
margin-left: 300px;
margin-top: 18px;

}
.upload-area .choose-file {
font-size: 15px;
font-size: 14px;
  margin-left: 210px;
  margin-top: 24.81px;
}
.modal-button{
  width: 145px;
  height: 30px;
  margin-left: 520px;
  font-size: 12px;
}

.uploaded-Success-Area-Doc{
  width: 700px ;
  height: 180px;
}
.modal-title-doc-success{
  
font-size: 16px;
margin-top: 22.83px;
margin-left: 245px;
}
.modal-description-doc-success{
font-size: 14px;
margin-top: 11px;
margin-left: 186px;
}
.success-details {
  margin-left: -10px;
  margin-top: 15px;
}
.modal-button-final-success{
  display: inline-flex;
padding: 10px 25px;
justify-content: center;
align-items: center;
font-size: 12px;
margin-left: 343.5px;
margin-top: 20px;
}
.Uploading-icon-doc{
  margin-left: 414px;
  margin-top:8px;
}
.doc-uploading-para-one{
  margin-top: 5.7px;
  margin-left: 344px;
}
.progress-area {
  margin-top: 26px;
 width: 634px;
height: 133px;
}
.progress-percentage{
  font-size: 14px;
}
.Uploading-icon-doc{
  margin-left: 314px;
  margin-top:6px;
}
.progress-bar {
  width: 555px;
height: 12px;
  margin-top: 5px;
  margin-left: 34px;
}
.doc-uploading-para-one{
  margin-left: 250px;
}
.upload-small-folder {
  max-width: none;
  width: 1.075rem;
  height: 1.075rem;
  margin-left: 1rem;
  margin-top: -1rem;
  position: static; 
}

}

@media (min-width: 1440px) and (max-width: 1536px){

}
@media (min-width: 1536px) and (max-width: 1920px){
 
}
@media (min-width: 1920px) and (max-width: 2560px){
 
}