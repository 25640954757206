@media (min-width: 1024px) and (max-width: 1280px) {
    
  
  
  
    .custom-input-field-contract {
      font-size: 0.875rem;
    }
  
    .input-container-holder {
      width: 90%;
      margin-left: 5%;
    }
  
    .custom-input-wrapper .input-container-holder {
      max-width: 600px;
    }
  }
  @media (min-width: 1280px) and (max-width: 1440px) {
  
 
  
    .custom-input-field-contract {
      font-size: 0.875rem;
    }
  
    .input-container-holder {
      width: 90%;
      margin-left: 5%;
    }
  
    .custom-input-wrapper .input-container-holder {
      max-width: 600px;
    }
  }
  @media (min-width: 1440px) and (max-width: 1536px) {
  
 
    .custom-input-field-contract {
      font-size: 0.875rem;
    }
  
    .input-container-holder {
      width: 92%;
      margin-left: 5%;
    }
  
    .custom-input-wrapper .input-container-holder {
      max-width: 600px;
    }
    
  }
  
  @media (min-width: 1536px) and (max-width: 1920px) {
  
  
    .custom-input-field-contract {
      font-size: 0.875rem;
    }
  
    .input-container-holder {
      width: 88%;
      margin-left: 5%;
    }
  
    .custom-input-wrapper .input-container-holder {
      max-width: 750px;
    }
  
  }
  
  @media (min-width: 1920px) and (max-width: 2560px) {
  
  
  
    .custom-input-field-contract {
      font-size: 0.875rem;
    }
  
    .input-container-holder {
      width: 92%;
      margin-left: 10px; 
      position: sticky; 
      top: 6rem; 
      z-index: 5; 
    }
  
    .custom-input-wrapper .input-container-holder {
      max-width: 1050px; 
      position: sticky;
      top: 6rem; 
      z-index: 5;
    }
  }


  