.otp-fullContainer{
    background-color: #FEF9F7;
    width: 100%;
    height: 99.3vh;
    
}

.main-otppage{
    position: absolute;
    top: 115px;
    bottom: 218px;
    right: 351px;
    left: 322px;
}
.otp-container{
    width: 437px;
height: 456px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: #FFF;
box-shadow: 43px 184px 53px 0px rgba(0, 0, 0, 0.00), 28px 118px 48px 0px rgba(0, 0, 0, 0.01), 15px 66px 41px 0px rgba(0, 0, 0, 0.05), 7px 30px 30px 0px rgba(0, 0, 0, 0.09), 2px 7px 17px 0px rgba(0, 0, 0, 0.10);
}
.otp-PageIcon{
    width: 220.344px;
height: 108.137px;
flex-shrink: 0;
margin-left: 102.93px;
margin-top: 24.53px;
background: #FFF;
}
.top-heading{
    margin-top: 40.33px;
    margin-left: 163px;
    color: var(--Text-h2, #171717);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #FFF;
}
.sub-heading{
    margin-top: 12px;
    margin-left: 10px;
    color: var(--Neutral-200, #52525B);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #FFF;
}
.otp-box{
    width: 52px;
height: 58px;
border-radius: 6px;
border: 1px solid rgba(23, 23, 23, 0.10);
background: var(--Background-main, #FEF9F7);
}
.otp-box-cont{
    margin-left: 32px;
    margin-top: 30px;
}
.resent-btn {
    color: #52525B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 141px;
}
.verify-btn{
    display: inline-flex;
    display: flex;
    width: 378px;
    padding: 12px 137px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: var(--Bg-Color, #FAF9F6);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
    border-radius: 6px;
background: var(--Primary-500, #C95EBE);
margin-top: 10px;
}

/* after verify page */
.otpverify-container{
    width: 677px;
height: 299px;
flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
    margin-top: 60px;
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
}
.verify-topHeading{
    color: var(--Text-h2, #171717);
    text-align: center;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.verify-subHeading{
    color: var(--Neutral-200, #52525B);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 12px;
}
.close-verifybtn{
    display: inline-flex;
    padding: 12px 60px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    background: #C95EBE;
}
.resend-btn{
    transition: 0.6s;
    cursor: pointer;
    color: rgba(82, 82, 91, 0.60);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 35px;
}
.resend-btn:active{
    scale: 0.9;
}
/* .otp-resend{
    margin-left: 20px;
} */
/* For the main button */
.otp-resend {
    background: none; /* Remove default background */
    border: none; /* Remove border */
    color: rgba(82, 82, 91, 0.60);
    font-family: Outfit, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 35px;
    margin-top: 5px;
  }
  
  /* For the "Did not receive OTP?" text */
  .otp-text {
    color: rgba(82, 82, 91, 0.60);
    font-family: Outfit, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    
  }
  
  /* For the "Resend OTP" text under span */
  .otp-resend-text {
    color: rgba(82, 82, 91, 0.60);
    font-family: Outfit, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline; /* Optional: to add underline to the "Resend OTP" text */
    margin-left: 4px; /* Add some space between the two spans */
  }
  .wrong-email{
    color: var(--Neutral_2, #52525B);
text-align: center;
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 20px;
  }


.go-back-button{
    color: var(--Primary-500, #C95EBE);
/* font-family: Outfit; */
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}