.account-box-area-profile {
    width: 81vw;
    height: 41vh;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 28px;
    margin-left: 210px;
}

.personal-info-account {
    color: var(--Neutral-1, #3F3F46);
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 24px;
    margin-left: 44px;
}
.email-container {
    margin-left: 290px;
}


.profile-section-account {}

.circle-account-profile {
    width: 80px;
    height: 80px;
    background-color: #C95EBE;
    border-radius: 50%;
    display: inline-block;
    margin-left: 44px;
    margin-top: 27px;
    position: relative;
}

.account-name-profile {
    color: #FFF;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 27px;
    margin-top: 28px;
    display: block;
}

.edit-icon {
   
    width: 24px;
    height: 24px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.edit-icon-account {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 10;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 26px;
    border: 0.5px solid rgba(63, 63, 70, 0.12);
    background: var(--White, #F5F4F3);
    box-shadow: 8px 13px 4px 0px rgba(0, 0, 0, 0.00), 5px 8px 4px 0px rgba(0, 0, 0, 0.01), 3px 5px 3px 0px rgba(0, 0, 0, 0.05), 1px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
}
.Full-Name-Account{
    color: #000;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-left: 44px;
margin-top: 32px;
}
.namehere-account{
    margin-left: 44px;
    margin-top: 11px;
    display: inline-flex;
height: 48px;
width: 367px;
padding: 14px 17px 14px 20px;
justify-content: center;
align-items: flex-start;
gap: 149px;
border-radius: 6px;
border: 1px solid rgba(0, 0, 0, 0.10);
background: #FFF;
}
.personal-info-account-password{
    align-self: stretch;
    color: var(--Text-h2, #171717);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 210px;
margin-top: 74px;
}
.personal-info-account-secure{
    color: #545454;
font-family: Outfit;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
align-self: stretch;
margin-left: 210px;
margin-top: 14px;
}
.button-container-profile {
    display: inline-flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
    
}

.account-button-profile-reset {
    display: inline-flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
color: var(--Primary-500, #C95EBE);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 165px;
height: 35px;
margin-left: 930px;
margin-top: 76px;
}
.account-button-profile-delete-account{
    display: inline-flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 6px;
background: rgba(212, 27, 27, 0.75);
color: var(--White, #F5F4F3);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 165px;
height: 35px;
margin-left: 855px;
margin-top: 76px;
}

.account-button-profile-cancel:hover {
    background: var(--Primary-500, #C95EBE);
    color: white;
}
.logout-account-btton{
    color: #D31C1C;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-left: 800px;
margin-top: 20px;
}
.popup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 163px;
    height: 88px;
    padding: 12px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.1), 7px 11px 13px rgba(0, 0, 0, 0.09);
    position: absolute;
    top: 85px; 
    left: 60px; 
    z-index: 10;
  }
  
  .popup-option {
    display: flex;
width: 139px;
height: 30px;
padding: 5px 52px 5px 12px;
align-items: flex-start;
gap: 12px;
flex-shrink: 0;
border-radius: 6px;
border: 0.5px solid rgba(0, 0, 0, 0.10);
  }
  
  .popup-option:hover {
    border: 1px solid black;
    background: #FFF;
    border-radius: 6px;
    padding: 5px;
  }
  
  .popup-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  