.close-button-folder-underfolder {
    position: absolute;
    top: 3px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #52525B;
    cursor: pointer;
    transition: color 0.3s;
    z-index: 1001;
  }
  
  .close-button-folder-underfolder:hover {
    color: #C95EBE;
  }

  .filter-modal-doc-underfolder {
    position: relative;
    margin: 0 auto;
    margin-left: 670px;
    margin-top: 120px;
    border-radius: 0px 12px 12px 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
box-shadow: 0px 160px 45px 0px rgba(0, 0, 0, 0.00), 0px 102px 41px 0px rgba(0, 0, 0, 0.01), 0px 58px 35px 0px rgba(0, 0, 0, 0.05), 0px 26px 26px 0px rgba(0, 0, 0, 0.09), 0px 6px 14px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    padding: 20px;
    width: 404px;
height: 339px;
flex-shrink: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .filter-modal-doc-underfolder h2 {
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }