@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  /* font-family: "Inter", sans-serif; */
}
body{
  box-sizing: border-box;
}

.App{
  background: var(--Background-main, #FEF9F7);
  height: 96vh;
}

:root {
  --Neutral-1: rgba(63, 63, 70, 1);
}
