.right-side-folder {
  width: 87vh;
  height: 85vh;
  background: #FFF;
}

.iframe-folder {
  width: 100%;
  height: 85vh;
}

.folder-list-container {
  display: flex;
  flex-direction: column;
}

.folder-list-scrollable {
  display: flex;
}

.folder-box {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.folder-icon-container {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 6px;
  background: transparent;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.folder-icon-container.active {
  border-radius: 6px;
border: 1px solid rgba(0, 0, 0, 0.10) !important;
background: #FFF !important;
box-shadow: 10px 15px 5px 0px rgba(0, 0, 0, 0.00), 6px 10px 5px 0px rgba(0, 0, 0, 0.01), 4px 5px 4px 0px rgba(0, 0, 0, 0.05), 2px 2px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
}
.generating-message {
  font-size: 16px;
  font-weight: bold;
  color: #6b7280;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.thinking-dots {
  width: 30px;
  height: 30px;
}

.loading-dots {
  display: inline-flex;
  margin-left: 5px;
}

.loading-dots span {
  animation: blink 1.2s infinite;
  color: #6b7280;
  font-size: 16px;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
}

.copy-popup {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  opacity: 0;
  animation: fade-in-out 2s;
  z-index: 1000;
}

@keyframes fade-in-out {
  0% {
      opacity: 0;
      transform: translateY(-5px);
  }
  10% {
      opacity: 1;
      transform: translateY(0);
  }
  90% {
      opacity: 1;
      transform: translateY(0);
  }
  100% {
      opacity: 0;
      transform: translateY(-5px);
  }
}

.folder-icon-container.active {
  border: 2px solid #2196f3;
  background-color: rgba(33, 150, 243, 0.1);
}

.folder-icon {
  width: 24px;
  height: 24px;
}
.folder-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.folder-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.folder-popup-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.folder-popup-message {
  margin-bottom: 20px;
}

.folder-popup-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.folder-popup-button:hover {
  background-color: #0056b3;
}

.folder-name {
  color: #000;
  font-family: outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.folder-list-container {
  width: 635px;
  height: 70px;
  flex-shrink: 0;
  padding-top: 10px;
  margin-top: -70px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  background: var(--Background-main, #FEF9F7);
  backdrop-filter: blur(5.099999904632568px);
}

.folder-list-scrollable {
  display: inline-flex;
  align-items: center;
  gap: 66px;
  margin-left: 130px;
}

.folder-container {
  position: relative;
  display: inline-block;
}

.folder-name {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.folderTooltip {
  visibility: hidden;
  background-color: white;
  color: var(--Neutral_2, #52525B);
text-align: center;
font-family: Outfit;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
width: 171px;
height: 30.016px;
flex-shrink: 0;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  font-size: 16px;
  left: 50%;
  border: 1px solid #52525B;
  transform: translateX(-50%);
  width: max-content;
  fill: var(--Background-main, #FEF9F7);
stroke-width: 1px;
stroke: var(--black_stroke, rgba(0, 0, 0, 0.10));
}

.folder-container:hover .folderTooltip {
  visibility: visible;
}

@media (min-width: 1024px) and (max-width: 1280px) {
 

  .custom-header-wrapper {
      width: 117%;
  }
  .custom-input-field-contract {
      font-size: 0.875rem;
  }
  .input-container-holder {
      width: 90%;
      margin-left: 5%;
  }
  .custom-input-wrapper .input-container-holder {
      max-width: 600px;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {

 
  .custom-input-field-contract {
      font-size: 0.875rem;
  }
  .input-container-holder {
      width: 90%;
      margin-left: 5%;
  }
  .custom-input-wrapper .input-container-holder {
      max-width: 600px;
  }
}

@media (min-width: 1440px) and (max-width: 1536px) {

  
  .custom-input-field-contract {
      font-size: 0.875rem;
  }
  .input-container-holder {
      width: 92%;
      margin-left: 5%;
  }
  .custom-input-wrapper .input-container-holder {
      max-width: 600px;
  }
  
}

@media (min-width: 1536px) and (max-width: 1920px) {
  
  .custom-input-field-contract {
      font-size: 0.875rem;
  }
  .input-container-holder {
      width: 88%;
      margin-left: 5%;
  }
  .custom-input-wrapper .input-container-holder {
      max-width: 750px;
  }
 
}

@media (min-width: 1920px) and (max-width: 2560px) {

 
  .custom-input-field-contract {
      font-size: 0.875rem;
  }
  .input-container-holder {
      width: 92%;
      margin-left: 10px;
      position: sticky;
      top: 6rem;
      z-index: 5;
  }
  .custom-input-wrapper .input-container-holder {
      max-width: 1050px;
      position: sticky;
      top: 6rem;
      z-index: 5;
  }
}



.popup-container-folderchat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content-folderchat {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  font-size: 14px;
  font-family: Outfit;
}

.close-popup-button-folderchat {
  margin-top: 10px;
  padding: 5px 10px;
  background: #C95EBE;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-popup-button-folderchat:hover {
  background: #C95EBE;
}
.popup-content-folderchat h2{
  font-size: 18px;
}