.excel-table-wrapper {
  border: 4px solid #000; 
  overflow: auto; 
}

.excel-table-wrapper table {
  width: 100%; 
  border-collapse: collapse; 
}

.excel-table-wrapper th {
  border: 2px solid #000; 
  padding: 3px;
}
.excel-table-wrapper td {
  border: 2px solid #000;
  padding: 3px;
}


/* Alternating row background colors
.excel-table-wrapper tr:nth-child(even) {
  background-color: #2c3e50; 
}

.excel-table-wrapper tr:nth-child(odd) {
  background-color: #16a085; 
} */
