/* *{
    font-family: "Inter", sans-serif;
    background-color: #fff;
    } */
.page-container{
    font-family: "Inter", sans-serif;
    background-color: #fff;
}
.main{
    position: absolute;
}
.logo-section{
    position: relative;
    top:32px;
    left: 28px;
    width: 131.77px;
    height: 24px;
}
.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f9f9f9; */
  }
  .logo{
    width: 64.425px;
    height: 64.425px;
    flex-shrink: 0;
  }
  /* .forgot-password-box {
     display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    background-color: #fff; 
    padding: 50px; 
    border-radius: 8px; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
    width: 350px;  
  } */
  
  /* .logo {
    width: 120px;
    margin-bottom: 20px;
  }
   */
  /* .icon-container {
    margin-bottom: 30px;
  } */
  

  .forgot-title {
    color: #171717;
    /* font-family: "Inter", sans-serif; */
    /* margin-bottom: 10px; */
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .forgot-subtitle {
    color: #52525B;
    margin-bottom: 30px;
    /* font-family: "Inter", sans-serif; */
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  /* .forgot-form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 568px;
    align-items: flex-start;
  } */
  /* .email-container {
    display: flex;
    flex-direction: column;
    gap: 8px; 
} */
.email-label {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #3F3F46;
    margin-bottom: 4px;
    margin-left: -280px;
}
  
  .email-input {
    padding: 15px;
    font-size: 16px;
    width: 568px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    margin-left: -290px;

  }
  
  .reset-password-btn {
    background-color: #8B55DD;
    color: white;
    padding: 16px 224px;; /* Adjust padding to fit the text */
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    width: 568px; /* Ensure button width adapts to the content */
    white-space: nowrap; /* Prevent text from wrapping */
    transition: 0.7s;
  }
  
  .reset-password-btn:hover {
    background-color: #7b4ccf;
  }
  .reset-password-btn:active{
    scale: 0.95;
  }
  .back-to-login {
    /* display: block; */
    margin-top: 20px;
    /* color: #7a7a7a; */
    font-family: "Inter", sans-serif;
    color: #52525B;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  
  /* .back-to-login:hover {
    color: #52525B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  } */
  


  /* second page design css */


    /* .page-container {
    font-family: "Inter", sans-serif;
    background-color: #fff;
} */

/* .forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */
.forgot-text{
  display: flex;
  width: 325px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.forgot-password-box {
    text-align: center;
    padding: 50px;
}

.email-heading {
  color: #171717;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.email-subtitle {
  color: #52525B;
  text-align: center;
  font-size: 14px;
  /* font-style: normal; */
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
  margin-bottom: 33px;
  align-self: stretch;
}

.open-email-btn {
    background-color: #8B55DD;
    color: white;
    border: none;
    padding: 16px 48px;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 16px;
}

.open-email-btn:hover {
    background-color: #7b4ccf;
}

.resend-link {
    font-size: 14px;
    color: #52525B;
    margin-bottom: 32px;
    transition: 0.7s;
}
.resend-link:active{
  scale: 0.95;
}

.resend-email {
    color: #D933B5;
    cursor: pointer;
}

/* .back-login-container {
    margin-top: 20px;
}

.back-to-login {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #7A7A7A;
}

.back-to-login img {
    width: 16px;
} */


