.chat-historyDiv {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 236px;
  height: 500px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 16px 16px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* no scroll on container, only in middle area */
  z-index: 1000;
}

/* Header (Search) pinned at top */
.chat-history-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  padding: 10px;
}

.ChatHistorySearchBox {
  width: 212px;
  height: 32px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #FFF;
  display: flex;
  padding: 8px 20px;
  align-items: center;
  font-family: Outfit, sans-serif;
  margin: auto;
  box-sizing: border-box;
}

.ChatHistorySearchBox input {
  width: 100%;
  height: 18px;
  color: #71717A;
  border: none;
  outline: none;
  font-family: Outfit, sans-serif;
}

/* Middle scrollable content */
.chat-history-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 10px; 
  box-sizing: border-box;
}

/* Footer pinned at bottom */
.chat-history-footer {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
}

/* New Chat button */
.start-newChat-button {
  width: 100%; /* Ensures full width */
  height: 36px;
  padding: 10px 20px;
  border-radius: 6px;
  background: rgba(139, 85, 221, 1);
  color: white;
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adds space between icon and text */
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.start-newChat-button img {
  width: 20px; /* Adjust icon size */
  height: 20px;
}


.start-newChat-button:hover {
  background: rgba(109, 65, 191, 1);
}


/* Empty state */
.ChatHistory-EmptyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* margin-top: 116px; */
}

.ChatHistory-EmptyContent h1 {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
  color: #18101C;
}

/* Section headings: Today, Yesterday, etc. */
.day-heading {
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin: 8px 0 4px;
}

/* Chat list / items */
.chat-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chat-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 6px 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.chat-item:hover {
  background-color: #f7f5f9;
  box-shadow: inset 0px 0px 0px 1px rgba(200, 170, 230, 0.5); /* Light border effect */
}

/* Single line truncation with ellipsis */
.chat-item-title {
  max-width: 210px; /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Options Icon (Three dots) */
.options-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: none; /* Hidden by default */
}


.chat-item:hover .options-icon {
  display: inline-block; /* Visible only on hover */
}

/* Dropdown Menu */
.chat-options-menu {
  position: absolute;
  top: 60px;
  right: 0px; /* Adjust for better positioning */
  transform: translateY(-50%);
  background: #fff;
  border-radius: 10px;
  padding: 6px 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 120px;
  z-index: 999;
}

/* Each Dropdown Item */
.chat-options-menu button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 6px 12px;
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  font-family: "Outfit", sans-serif;
  cursor: pointer;
  transition: background 0.2s ease;
}

/* Hover effect for buttons */
.chat-options-menu button:hover {
  background: rgba(230, 225, 240, 0.5);
}

/* Rename Button Styling */
.rename-button {
  color: #333;
}

/* Delete Button Styling */
.delete-button {
  color: #e63946; /* Red text */
  font-weight: 500;
}

/* Icon styles */
.option-icon {
  width: 16px;
  height: 16px;
}

.ChatHistory-NoResults {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ChatHistory-NoResults img {
  width: 50px;
  margin-top: 10px;
}

