@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

.menu {
  width: 20%;
  position: fixed;
  left: 0;
  height: 100vh;
  background-color: #f0f0f0;
  /* transition: transform 0.3s ease; */
}

.border-blue-500 {
  border-color: #3b82f6; /* Blue */
}
.border-gray-300 {
  border-color: #d1d5db; /* Gray */
}


.upload-mainpage {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* height: 100vh; */
  height: calc(100vh - 70px); 
  /* overflow: hidden; */
  /* background-color: white; */
  background: rgb(232,192,255);
  background: radial-gradient(circle, rgb(238, 213, 253) 15%, rgba(255,255,255,1) 100%);
  overflow: hidden;
}

.mainAI-container{
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.upload-section{
    border-radius: 12px;
    border: 1px dashed rgba(0, 0, 0, 0.20);
    background: rgba(217, 194, 253, 0.15);
}
.backup-logo{
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.10)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.09)) drop-shadow(1px 8px 5px rgba(0, 0, 0, 0.05)) drop-shadow(1px 14px 6px rgba(0, 0, 0, 0.01)) drop-shadow(2px 22px 6px rgba(0, 0, 0, 0.00));
}
.card-question{
    display: flex;
    width: 320px;
    padding: 16px 8px;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 9px;
    border: 1px solid var(--new-stroke, #C95EBE);
    background: rgba(143, 143, 143, 0.05);
    backdrop-filter: blur(5.099999904632568px);
}
/* .chat-section{
    display: inline-flex;
    padding: 12px 32px 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 711px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
} */
.chat-section {
   
    align-items: center;
    /* padding: 8px 28px ; */
    /* gap: 12px; */
    /* width: 1072px; */
    max-width: 710px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    justify-content: center;
    margin-left: 200px;
  }
  

  
  .chat-button {
    width: 56px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #6F52ED;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    transition: transform 0.3s ease-out, background-color 0.3s ease-out;
    /* position: absolute; */
}

.chat-button:hover {
    background: #5A42C9; /* Slightly darker shade */
    /* transform: translate(-2px); */
}


  .uploadButton{
    border: 1px solid #0000001A;
    border-radius: 20px;
    height: 60px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-right: 12px;
  }
  .uploadButton>span{
    height: 20px;
    width: 52px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 20.16px;
  }

  .uploadButton>img{
    height: 20px;
    width: 20px;
    margin-left: 16px;
  }

  .uploadButton:active {
    box-shadow: 0px 47px 13px 0px rgba(0, 0, 0, 0.00),
                0px 30px 12px 0px rgba(0, 0, 0, 0.01),
                0px 17px 10px 0px rgba(0, 0, 0, 0.05),
                0px 7px 7px 0px rgba(0, 0, 0, 0.09),
                0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  }
  
  .active-shadow {
    box-shadow: 0px 47px 13px 0px rgba(0, 0, 0, 0.00),
                0px 30px 12px 0px rgba(0, 0, 0, 0.01),
                0px 17px 10px 0px rgba(0, 0, 0, 0.05),
                0px 7px 7px 0px rgba(0, 0, 0, 0.09),
                0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  }
  

  
 
  /* Chat history container with a border and scroll behavior */
.chat-history-container {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  height: 280px; /* Adjust height as needed */
  overflow-y: auto; /* Adds vertical scroll when content overflows */
  background-color: #f9f9f9;
  margin: 0 auto; /* Center container horizontally */
  display: flex; /* Ensures proper alignment of child elements */
  flex-direction: column;
  margin-top: -100px;
  margin-left: 86px;
}

/* Chat bubble styles */
.chat-bubble {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%; /* Limit bubble width */
  word-wrap: break-word;
}

/* User chat styling (right side) */
.user-chat {
  background-color: #d1e7ff;
  color: #000;
  align-self: flex-end; /* Align to the right */
  text-align: right;
  margin-left: auto;
}

/* AI chat styling (left side) */
.system-chat {
  background-color: #f1f1f1;
  color: #000;
  align-self: flex-start; /* Align to the left */
  text-align: left;
  margin-right: auto;
}

.AI-chat-input {
  flex-grow: 1;
  border: none;
  outline: none;
  min-width: 300px;
  max-width: auto;
  min-height: 40px; /* Minimum height */
  max-height: 150px; /* Limit the expansion */
  margin-left: 10px;
  margin-top: 12px;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  color: #71717A;
  resize: none; /* Prevent manual resize */
  overflow-y: scroll; /* Hide scrollbar unless needed */
}

.AI-chat-input-section {
  display: flex;
   align-items: center;
  justify-content:space-between; 
  max-width: 934px;
  min-width: 934px;
  min-height: 60px; /* Minimum starting height */
  max-height: 250px; /* Limit expansion */
  height: auto;
  border-radius: 20px;
  border: 4px solid transparent;
  background: #FFF;
  transition: height 0.2s ease-in-out;
}
/* Active state styling: when input is focused or contains text */
.AI-chat-input-section.active {
  border: 1px solid var(--new-stroke, #C95EBE);
}

.inputFieldWrapper {
  width: 100%;
  display: flex;
  align-items:flex-start;
}


/* .chat-input-section:focus-within {
  border-image-source: linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%);
  border-image-slice: 1;
  outline: none;
} */
.searchContent{
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}


.AI-Container{
  /* border: 2px solid red; */
  height: 100%;
  display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.topContainer{
  height: 134px;
  width: 100%;
  /* border: 2px solid red; */
  margin-top: 70px;
  /* background-color: white; */
}
.topContainer>h6{
  width: 462px;
  height: 30px;
  font-family: 'Outfit', sans-serif; /* Ensure 'Outfit' font is imported */
  font-size: 24px;
  font-weight: 300;
  line-height: 30.24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.topContainer>span{
  /* border: 2px solid red; */
   margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 34.13px;
  letter-spacing: -0.03em; 

}
.topContainer>p{
  /* border: 2px solid red; */
  margin-top: 15px;
  font-family: 'Outfit', sans-serif; 
  font-weight: 400;
  font-size: 15px;
  line-height: 18.9px;
  text-align: center;
  width: 313px;
  height: 38px;
}

.secondContainer{
  height:auto;
  width: 100%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: flex-start; 
  flex-direction: column;
  align-items: center;
  padding: 20px;
  /* background-color: white; */
}
.thirdContainer{
  height: auto;
  width: auto;
  /* border: 2px solid red; */
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  /* margin-top: 2px; */
}

.secondContainer>.card_container{
  /* border: 1px solid green !important;  */
  width: 100%;
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  justify-content: center;
  gap: 40px;
  margin: 0px;
  border: none;
  margin-top: 80px;

}
.card {
  display: flex;
  flex-direction: column;
  padding: 8px;
  /* border: 4px solid transparent; */
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
  width: 200px;
  height: 100px;
  transition: border 0.3s ease;
  cursor: pointer;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));

}
.card.active {
  border: 1px solid var(--new-stroke, #C95EBE);
  /* You can also add additional styling changes if needed, for example: */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}
/* .card:first-child:focus,
.card:first-child:active {
  border-image-source: linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%);
  border-image-slice: 1;
  border-radius: 12px;
  outline: none;
} */

.card.disabled {
  pointer-events: none; /* Disables interaction */
  opacity: 0.5; /* Makes the card appear inactive */
  cursor: not-allowed; /* Shows "not allowed" cursor */
}


.card>img {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  /* margin-left: 10px; */

}

.card>p {
  color: var(--Neutral-1, rgba(63, 63, 70, 1));
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  
  /* width: 176px; */
}
.explorePrompt>button{
  /* border: 1px solid red; */
  margin-bottom: 60px;
  margin-top: 32px;
  width: 136px;
  height: 20px;
  display: flex;
   
  }
  .explorePrompt>button>span{
    height: 18px;
    width: 104px;
    font-family: 'Outfit', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.64px;
  }
    
  .explorePrompt>button>img{
    margin-left:12px;
    height:20px;
    }

    .AI-CP-ChatHistoryContainer{
      /* border: 1px solid red; */
    left: 260px;
    position: absolute;
    top: 100px;
}
    
  .chat-historyBtn{
  /* border: 1px solid red; */
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  width: 160px;
  height: 36px;
  border-radius: 6px;
  padding: 8px 16px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
box-shadow: 5px 10px 11px 0px rgba(0, 0, 0, 0.09);
box-shadow: 11px 23px 15px 0px rgba(0, 0, 0, 0.05);
box-shadow: 20px 41px 18px 0px rgba(0, 0, 0, 0.01);
box-shadow: 31px 64px 20px 0px rgba(0, 0, 0, 0);


    }

    .chat-historyBtn>span{
      width: 92px;
      height: 20px;
      font-family: 'Outfit', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 20.16px;
      color: rgba(82, 82, 91, 1);
    }
    .chat-historyBtn>img{
        height: 20px;
        width: 20px;
        color: rgba(82, 82, 91, 1);
        /* border: 1px solid red; */
   }

/* Media Query for Screens Between 768px and 1270px */
@media only screen and (min-width: 768px) and (max-width: 1270px) {
  /* Adjust menu width */
  .menu {
    width: 25%;
  }
  
  /* Reduce overall height for main container */
  .upload-mainpage {
    height: calc(100vh - 70px);
    /* height: auto; */
    /* border: 1px solid red; */
  }
  .AI-container{
    /* border: 1px solid red; */

  }
  /* Adjust chat history container for smaller screens */
  .chat-history-container {
    max-width: 100%;
    height: 250px;
    margin-left: 20px;
    margin-top: -50px;
  }

  .topContainer{
    height: auto;
    width: 100%;
    /* border: 2px solid red; */
    margin-top: 10px;
    /* background-color: white; */
  }
  /* Scale down header text sizes and widths */
  .topContainer > h6 {
    width: 80%;
    font-size: 20px;
  }
  
  .topContainer > span {
    font-size: 24px;
  }
  
  .topContainer > p {
    width: 90%;
    font-size: 14px;
  }
  .secondContainer>.card_container{
  
    gap: 32px;
    margin: 0px;
    border: none;
    margin-top: 10px;
  
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 4px solid transparent;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 206px;
    height: 100px;
    transition: border 0.3s ease;
    cursor: pointer;
  }
  .thirdContainer{
    height: auto;
    width: auto;
    /* border: 2px solid red; */
    margin-bottom: 5px;
    margin-left: -150px;
   }
  

  /* Adjust chat history button */
  .chat-historyBtn {
    width: 150px;
    height: 32px;
  }
  
  .chat-historyBtn > span {
    font-size: 14px;
    width: auto;
  }
  

  .AI-chat-input {
    flex-grow: 1;
    border: none;
    outline: none;
    min-width: 300px;
    max-width: auto;
    min-height: 40px; /* Minimum height */
    max-height: 150px; /* Limit the expansion */
    margin-left: 5px;
    margin-top: 12px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    color: #71717A;
    resize: none; /* Prevent manual resize */
    overflow-y: scroll; /* Hide scrollbar unless needed */
  }
  
  .AI-chat-input-section {

    max-width: 314px;
    min-width: 634px;
    height: auto;
    border-radius: 20px;
    border: 4px solid transparent;
    background: #FFF;
    transition: height 0.2s ease-in-out;
  }
  
  .inputFieldWrapper {
    width: 50%;
    display: flex;
    align-items:flex-start;
  }
  

  .AI-CP-ChatHistoryContainer{
    /* border: 1px solid red; */
  left: 220px;
}
}