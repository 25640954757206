
/* *{
    font-family: "Inter", sans-serif;
} */
.plan-page {
    font-family: "Inter", sans-serif;
    /* background: #fff; */
}
/* .plan-Container {
    padding-top: 20px;
    max-height: 1011px; 
    max-width: 1366px; 
} */
/* .top-planheading {
    color:  #BB42AE;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } */
.progress-Bar{
    width: 1207px;
    height: 9px;
    flex-shrink: 0;
    border-radius: 14px;
    background: #D9D9D9;
}
.progress-active{
    width: 842px; 
    height: 9px;
    background-color: #BB42AE;
    border-radius: 14px; 
}
/* .plan-top{
    color: #18101C;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
} */
.plan-subtop{
    color:  #52525B;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


/* .monthly-yearly-box{
    width: 331px;
    height: 64px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    background-color: #FFF;
} */
.monthly-text{
    color:  #52525B;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.yearly-text{
    color:  #52525B;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.active-box {
    border-radius: 6px;
    border: 1px solid var(--Secc, #8B55DD);
    background: var(--White, #F5F4F3);
    box-shadow: 83px 86px 33px 0px rgba(0, 0, 0, 0.00), 
                53px 55px 31px 0px rgba(0, 0, 0, 0.01), 
                30px 31px 26px 0px rgba(0, 0, 0, 0.05), 
                13px 14px 19px 0px rgba(0, 0, 0, 0.09), 
                3px 3px 11px 0px rgba(0, 0, 0, 0.10);
  }
  
  .monthly-active-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 52px;
    flex-shrink: 0;
  }
  
  .yearly-active-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 207px;
    height: 52px;
    flex-shrink: 0;
  }
  
.free-text{
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background-color: rgba(139, 85, 221, 0.30);
    color:  #8B55DD;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card{
    box-shadow: rgba(208, 204, 204, 0.35) 0px 5px 15px;
    border-radius: 16px;
    /* border: 1px solid rgba(0, 0, 0, 0.10); */
    background: #FFF;
}
.offers{
    width: 157px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 4px;
    /* background: #ECE0FD; */
    color: var(--Secc, #8B55DD);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-header{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.plan-des{
    color:  #8B55DD;
    font-style: normal;
    font-size: 13px;
    font-weight: 700;
    line-height: normal;
}
.card-sub-header{
    color:  #18101C;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.card-price{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.monthly-yearly{
    color:  #52525B;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-btn{
    display: flex;
    padding: 12px 89px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    /* border: 1px solid var(--Secc, #8B55DD); */

    /* color: var(--Secc, #8B55DD); */
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.credit-text{
    color: #2C1338;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.card-processBar{
    width: 339px;
    height: 1px;
    background: linear-gradient(180deg, #C95EBE 0%, #8B55DD 100%);
}
.card-include{
    color: #383838;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-users{
    width: 143px;
    height: 28px;
    display: flex;
    padding: 4px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgba(187, 66, 174, 0.16);
    color:  #BB42AE;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.card-points-container{
    color:  #3F3F46;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


/* payment success page */
.text-paymentpage{
    display: flex;
    width: 415px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.payment-container{
    border: 1px solid rgba(0, 0, 0, 0.20);
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 58px 267px 109px 0px rgba(0, 0, 0, 0.01), 33px 150px 92px 0px rgba(0, 0, 0, 0.05), 14px 67px 68px 0px rgba(0, 0, 0, 0.09), 4px 17px 38px 0px rgba(0, 0, 0, 0.10);
}

.home-btn{
    font-family: "Outfit", sans-serif;
}
