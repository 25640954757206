.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.filter-modal {
  background: #fef9f6;
  padding: 1.5rem;
  width: 25.25rem;
  height: 22.625rem;
  flex-shrink: 0;
  margin-left: 8.125rem;
  margin-top: -0.9375rem;
  text-align: left;
  position: relative;
  border-radius: 0 0.75rem 0.75rem 0.75rem;
  border: 0.0625rem solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: var(--Background-main, #FEF9F7);
  box-shadow: 0 10rem 2.8125rem rgba(0, 0, 0, 0), 0 6.375rem 2.5625rem rgba(0, 0, 0, 0.01),
    0 3.625rem 2.1875rem rgba(0, 0, 0, 0.05), 0 1.625rem 1.625rem rgba(0, 0, 0, 0.09),
    0 0.375rem 0.875rem rgba(0, 0, 0, 0.1);
}

.close-button-filter {
  position: absolute;
  top: 0;
  right: 1rem;
  border: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6e465f;
  transition: color 0.3s;
}

.close-button:hover {
  color: #a0307e;
}

.filter-title {
  color: var(--Neutral_2, #52525B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.clear-filter-button-filter{
  color: var(--Primary-500, #C95EBE);
  margin-top: 1.0625rem;
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: inline-flex;
padding: 7px 15px 9px 20px;
justify-content: center; 
align-items: center;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
}
.quick-select-label {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}

.filter-select{
  width: 100%;
  padding: 0.75rem;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.5rem;
  padding-right: 1.875rem;
  border: 0.0625rem solid #ddd;
  color: #6e465f;
  margin-bottom: 1.125rem;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s, box-shadow 0.3s;
}
.quick-select {
  width: 100%;
  padding: 0.75rem;
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.5rem;
  padding-right: 1.875rem;
  border: 0.0625rem solid #ddd;
  color: #6e465f;
  margin-bottom: 1.125rem;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-select-wrapper img {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 1rem;
  height: 1rem;
}
.icon-filter-drop{
  margin-top: -10px;
}
.custom-select-wrapper1 img {
  position: absolute;
  right: 0.625rem;
  top: 80%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 1rem;
  height: 1rem;
}

.filter-select:focus,
.quick-select:focus {
  border-color: #a0307e;
  box-shadow: 0 0 0.375rem rgba(160, 48, 126, 0.3);
}

.filter-select option {
  width: 21rem;
  height: 2.375rem;
  flex-shrink: 0;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: var(--Background-main, #FEF9F7);
}

.quick-select option {
  width: 21rem;
  height: 2.375rem;
  flex-shrink: 0;
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--black_stroke, rgba(0, 0, 0, 0.1));
  background: var(--Background-main, #FEF9F7);
}

.custom-select-wrapper {
  position: relative;
  width: 22.75rem;
  flex-shrink: 0;
  margin-top: 0.9375rem;
  height: 2.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.custom-select-wrapper1 {
  position: relative;
  width: 22.75rem;
  flex-shrink: 0;
  margin-top: 0.9375rem;
  height: 2.75rem;
  border-radius: 1.4375rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.date-picker-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.1875rem;
  gap: 0.75rem;
}

.date-picker label {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.date-input-wrapper {
  position: relative;
  width: 10rem;
  height: 2.5rem;
}

.calendar-icon-filter {
  position: absolute;
  left: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  color: #6e465f;
}

.date-input-filter {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0.75rem 0.75rem 0.75rem 2.25rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid #ddd;
  color: #6e465f;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s, box-shadow 0.3s;
  -webkit-appearance: none;
}

.date-input-wrapper:hover,
.date-input-wrapper:focus-within {
  border-color: var(--black_stroke, rgba(0, 0, 0, 0.1));
}

.date-input-filter::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.apply-filter-button-filter {
  margin-top: 1.0625rem;
  margin-left: 0rem;
  display: flex;
  padding: 0.5rem 1.75rem;
  justify-content: center;
  align-items: center;
  color: var(--Bg-Color, #FAF9F6);
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: var(--Primary-500, #C95EBE);
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}



.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.button-container-filter {
  display: flex;
  justify-content: space-between; /* Ensures "Clear All" on the left and "Apply Filterss" on the right */
  align-items: center; /* Aligns buttons vertically in the center */
  margin-top: 1rem; /* Add spacing above the buttons if needed */
}
