

/* .account-page{
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #fff;
}
.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;  
  } */
  
  /* .formContainer {
    background-color: #fff;
    
  } */
  .account-page{
    position: relative;
    background-color: #fff;
  }
  .pageContainer {
    position: absolute;
      /* display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100vh;  */
       top: -80px;
       left: 117px;
       right: 116px;
       
    }
  
  .formTitle {
    color:  #BB42AE;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .formDescription{
    color:  #18101C;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }
  .formSubdescription {
    color: #52525B;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .progressBar {
    width: 1207px;
    height: 9px;
    flex-shrink: 0;
    border-radius: 14px;
    background: #D9D9D9;
    margin-bottom: 48px;
    /* position: relative;  */
  /* overflow: hidden;  */
  }
  
  .progress {
    width: 204px; 
    height: 9px;
    background-color: #BB42AE;
    border-radius: 14px; 
    /* position: absolute;
    top: 0;
    left: 0; */
  }
  
  .detailsForm {
    display: flex;
    flex-direction: column;
  }
  
  .formRow {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-page {
    flex: 1;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  input {
    padding-left: 20px;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.10);
    transition: none;
    font-size: 16px;
  }
  input:hover, input:focus {
    font-size: 16px; 
    transition: none; 
  }
  
  .verifyBtn {
    color:  #C95EBE;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    left: 490px;
     right: 24px;
     transition: 0.6s;
  }
  .verified-mark{
    left: 515px;
     right: 24px;
  }
  .verifyBtn:hover {
    cursor: pointer;
    /* background-color: #C95EBE; */
  }
  .verifyBtn:active{
    scale: 0.9;
  }
  
  .continueBtn {
    width: 200px;
    background-color: #C95EBE;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 16px 40px;
    gap: 12px;
    border-radius: 12px;
  }
  .continueBtn .send-logo{
    padding: 0;
    background-color:  #C95EBE;
  }
 
  .continueBtn:hover {
    background-color: #C95EBE;
  }
  
  @media (max-width: 768px) {
    .formRow {
      flex-direction: column;
    }
  }
