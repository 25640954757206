.edit-input-lineitems {
  width: 200px;
  height: 33px;
  flex-shrink: 0;
  background: var(--Background-main, #FEF9F7);
  color: var(--Text-h1, #18101C);
  font-family: Outfit, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px;
  border-radius: 4px;
}

.done-linesitens {
  color: var(--Primary-500, #C95EBE);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 14px;
}

.delete-lineitems {
  margin-left: 10px;
}

.edit-lineitems {
  margin-left: 15px;
}

.table-data-lineitems {
  color: var(--Neutral_2, #52525B);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.table-head-lineitems {
  color: #000;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding: 8px;
}