.filter-modal-overlay-doc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .close-button-folder {
    position: absolute;
    top: 3px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #52525B;
    cursor: pointer;
    transition: color 0.3s;
    z-index: 1001;
  }
  
  .close-button-folder:hover {
    color: #C95EBE;
  }
  
  
 
  
  .filter-modal-doc {
    position: relative;
    margin: 0 auto;
    margin-left: 670px;
    border-radius: 0px 12px 12px 12px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: var(--Background-main, #FEF9F7);
box-shadow: 0px 160px 45px 0px rgba(0, 0, 0, 0.00), 0px 102px 41px 0px rgba(0, 0, 0, 0.01), 0px 58px 35px 0px rgba(0, 0, 0, 0.05), 0px 26px 26px 0px rgba(0, 0, 0, 0.09), 0px 6px 14px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    padding: 20px;
    width: 404px;
height: 339px;
flex-shrink: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .filter-modal-doc h2 {
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .filter-options label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  
  .filter-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }

.chekcbox-doc-filter {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin-left: 20px;
  border: 1px solid #C95EBE; 
  background-color: #fff; 
  position: relative;
  cursor: pointer;
  appearance: none; 
  outline: none; 
  transition: border 0.3s;
}

.chekcbox-doc-filter:checked {
  border-color: #8B55DD; 
}


.chekcbox-doc-filter:checked::after {
  content: "\2713"; 
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 16px;
  color: #8B55DD; 
  transform: translate(-50%, -50%); 
  font-family: Arial, sans-serif; 
}

.chekcbox-doc-filter:hover {
  border-color: #8B55DD; 
}
.filter-actions-folder{
  display: flex;
  gap: 10px;
  margin-left: 120px;
  margin-top: -50px;
}
  .filter-actions-folder button {
    display: flex;
padding: 7px 17px 9px 18px;
justify-content: center;
align-items: center;
border-radius: 6px;
border: 1px solid var(--Primary-500, #C95EBE);
  }
  
  .filter-actions button:first-of-type {
    background: #e0e0e0;
  }

  .Box-rec{
    width: 122px;
height: 288px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
background: #FFF;
margin-top: 10px;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-left: -20px;
  }

  .Box-doc.active-tab {
    border-bottom: 2px solid #6a0dad; 
    font-weight: bold;
  }
  
  .Box-doc{
    display: flex;
    color: var(--Text-h1, #18101C);
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 20px 10px;
    gap: 5px;
  }
  .Display{
    display: flex;
  }

  .Header-doc-filter-folder{
    width: 223px;
  }

  .Header-doc-filter-folder p{
    color: var(--Neutral_2, #52525B);
font-family: Outfit;
font-size: 13.316px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 13px;
margin-left: 10px;
  } 

  .slider-container-folder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.slider {
  width: 247px;
  height: 10px;
  flex-shrink: 0;

  border-radius: 5px;
  background: var(--sec_shade, rgba(139, 85, 221, 0.16)); 
  appearance: none;
  outline: none;
  position: relative;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: var(--sec_shade, rgba(139, 85, 221, 0.16));
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--Secc, #8B55DD); 
  cursor: pointer;
  border: 2px solid white;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--Secc, #8B55DD); 
  cursor: pointer;
  border: 2px solid white;
}

.slider-progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    to right,
    var(--Secc, #8B55DD) 0%,
    rgba(139, 85, 221, 0.16) 0%
  );
  pointer-events: none;
}
.dropdown-container {
    position: relative;
    width: 50px;
    margin: 0 auto;
    margin-left: 10px;
  }
  
  .dropdown-filter-folder-here {
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
  
    border-radius: 23px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
    background-color: #fdf9ff;
    color: #6b4a7d;
    appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
    width: 222px;
height: 36px;
flex-shrink: 0;
  }
  .select-here-folder-here{
    padding-top: -20px;
  }
  .dropdown-filter-folder-here option {
    color: var(--text_non_active, #73607B);
font-family: Outfit;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .dropdown-filter-folder-here:hover {
    border-color: #8b55dd;
  }
 
  .dropdown-container-folder::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 115px;
    transform: translateY(-50%);
    color: #8b55dd;
    pointer-events: none;
  }
  .dropdown-container-folder {
    position: relative;
    width: 242px;
    margin: 0 auto;
  }
  
  .or-divider {
    text-align: center;
    margin: 10px 0;
    position: relative;
  }
  
  .or-divider span {
    padding: 0 10px;
    color: var(--text_non_active, #73607B); 
    font-family: Outfit, sans-serif; 
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    margin-left: -10px;
  }
  
  .or-divider::before,
  .or-divider::after {
    content: "";
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    width: 86px; 
  }
  
  .or-divider::before {
    left: 15px; 
  }
  
  .or-divider::after {
    right: 16px; 
  }
  
  .date-range-container-folder {
    display: flex;
    justify-content: space-between;
    gap: 2px;
    margin-top: 10px;
  }
  
  .date-picker-folder {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .date-label-folder {
    color: var(--text_non_active, #73607B);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 20px;
  }
  
  
  .date-input-folder {
    
    display: inline-flex;
    padding: 10px 24px 10px 12px;
    align-items: center;
    /* width: 100%; */
    width: 110px;
    height: 45px;
    background-color: #fff;
    border-radius: 4px;
    margin-left: 10px;
border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  }
  
  .date-input-folder img {
    width: 20px;
    height: 20px;
  }
  
  .date-input-folder input[type="date"] {
    border: none;
    outline: none;
    width: 40%;
    font-size: 14px;
    font-family: Outfit;
    font-weight: 600;
    color: #73607B;
    background: transparent;
  }
  .clear-all-filter{
    color: var(--Primary-500, #C95EBE);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
height: 40px;
width: 120px;
  }
  .apply-filter{
    color: var(--Bg-Color, #FAF9F6);
font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
padding: 7px 20px 9px 20px;
justify-content: center;
align-items: center;
border-radius: 6px;
height: 40px;
width: 130px;
background: var(--Primary-500, #C95EBE);
  }