/* .home-container{
    background: #CC95C0; 



} */

.upload-button-container:hover .upload-text {
  color: black;
  font-weight: 600;
  /* background-color: rgb(55, 65, 81); */
}

.sub-text {
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
     */
  font-weight: 600;
}
