.Contents-Full-Container {
  height: 100vh;
}

.folder-content-info {
  display: flex;
  margin-left: 213px;
  margin-top: 31px;
}

.under-folder-delete-popup {
  margin-left: 10px;
}

.document-underfolder {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  margin-top: 8px;
  margin-right: 100px;
  flex-grow: 1;
  width: 85%;
  max-width: 100%;
  margin-left: 200px;
  max-height: calc(100vh - 200px);
  box-sizing: border-box;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: auto;
}

.action-buttons-content {
  display: flex;
  gap: 12px;
  margin-left: auto;
}

.ask-copilot-button-content {
  width: 177px;
  height: 40px;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color: var(--Primary-500, #C95EBE);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-300, 24px);
  letter-spacing: 0.14px;
  border: 1px solid var(--Primary-500, #C95EBE);
}

.upload-button-content {
  width: 120px;
  height: 40px;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color: var(--White, #F5F4F3);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-300, 24px);
  letter-spacing: 0.14px;
  border: 1px solid var(--Primary-500, #C95EBE);
  margin-right: 40px;
  background: var(--Primary-500, #C95EBE);
}

.folder-icon,
.options-icon {
  height: 24px;
  width: 24px;
}

.options-icon {
  gap: 100px;
}

.main-content {
  display: flex;
  gap: 8px;
}

.folder-name-underfolder {
  color: var(--Text-h1, #18101C);
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.file-content-desc {
  display: flex;
  align-items: center;
  margin-left: 200px;
}

.created-date {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 7px;
  color: var(--Neutral_2, #52525B);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter-container-folder {
  display: flex;
  margin-top: -29px;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}

.search-box-underfolder {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f5f5f5;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.search-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.search-input {
  border: none;
  outline: none;
  font-size: 14px;
  color: #71717a;
  font-family: "Outfit", sans-serif;
  flex: 1;
  background-color: transparent;
}

.document-filter-button-inside {
  margin-top: 35px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.rename-under-doc {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desc-under-doc {
  color: var(--Neutral_2, #52525B);
  font-family: Outfit;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.desc-under-doc-title {
  color: var(--Neutral-200, #52525B);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 70px;
}

.filter-button:hover {
  background-color: #f0f0f0;
  border-color: #8b55dd;
}

.filter-icon {
  width: 20px;
  height: 20px;
}

.filter-label {
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  color: #8b55dd;
}

.content-button1 {
  color: var(--Primary-500, #C95EBE);
  width: 150px;
  height: 40px;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-300, 24px);
  letter-spacing: 0.14px;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  margin-left: 838px;
  border: 1px solid var(--Primary-500, #C95EBE);
}

.content-button2 {
  color: var(--White, #F5F4F3);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typography-Line-Height-300, 24px);
  letter-spacing: 0.14px;
  width: 150px;
  height: 40px;
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  margin-left: 40px;
  border-radius: 6px;
  border: 1px solid var(--Primary-500, #C95EBE);
  background: var(--Primary-500, #C95EBE);
}

.table-container {
  width: 1232px;
  height: 315px;
  margin-left: 220px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow-x: auto;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #eaeaea;
}

.styled-table th {
  background-color: #f9f9f9;
  font-weight: 600;
  color: #666;
}

.styled-table thead th {
  text-transform: uppercase;
}

.styled-table tbody tr:hover {
  background-color: #f5f5f5;
}

.header-checkbox,
.row-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.file-name-cell {
  display: flex;
  align-items: center;
}

.file-icon {
  width: 24px;
  height: 24px;
}

.file-details {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: 600;
  color: #333;
}

.file-type {
  font-size: 12px;
  color: #666;
}

.upload-date {
  font-size: 12px;
  color: #666;
}

.upload-date span:first-child {
  font-weight: 500;
  color: #333;
}

.action-cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: 1px solid #8b55dd;
  background-color: transparent;
  border-radius: 6px;
  color: #8b55dd;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}
  
.chat-button-doc-underfolder {
  width: 120px;
  height: 37px;
  display: inline-flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  /* border: 1px solid var(--Secc, #8B55DD); */
  color: #A04A97;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Ensures proper positioning for the pseudo-element */
}



.chat-button-doc-underfolder:hover::after {
  content: "Click to Chat"; /* Text to display on hover */
  position: absolute;
  bottom: 25px; /* Adjust position below the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #f4e1fc;
  color: #A04A97;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.chat-button:hover {
  background-color: #f4f1fc;
}

.chat-button img {
  width: 16px;
  height: 16px;
}

.more-options {
  cursor: pointer;
  font-size: 20px;
  color: #666;
}

.custom-table-wrapper {
  width: 85%;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-left: 200px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Outfit", sans-serif;
  border-radius: 6px;
  background: #FFF;
}

.custom-table thead {
  background-color: #f9f9f9;
  color: #52525b;
}

.custom-table th,
.custom-table td {
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
}

.custom-table th {
  color: var(--text_non_active, #73607B);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-table td {
  color: var(--Neutral-1, #3F3F46);
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.custom-table tbody tr:hover {
  background-color: #e9e9e9;
  transition: background-color 0.2s ease;
}

.custom-table-wrapper::-webkit-scrollbar {
  width: 8px;
}

.custom-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.custom-table-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.folder-custom-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
  appearance: none;
  cursor: pointer;
  position: relative;
}

.folder-custom-checkbox:checked::after {
  content: "✓";
  color: "#52525B";
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination-underfolder {
  position: absolute;
  bottom: 34px;
  left: 201;
  width: 81.8vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-left: 20px;
  border-radius: 6px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-arrow {
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  margin: 0 5px;
}

.pagination-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-text {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination-text select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-container-underfolder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content-folder {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  width: 446px;
  height: 192px;
  flex-shrink: 0;
  text-align: center;
  padding: 1.5rem;
  animation: fadeIn 0.3s ease-in-out;
  z-index: 10000;
}

.popup-content-folder h2 {
  color: var(--Text-h2, #171717);
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.popup-content-folder p {
  color: var(--Neutral-150, #71717A);
  text-align: center;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 374px;
}

.popup-close-button-folder {
  background: var(--Primary-500, #C95EBE);
  border: none;
  color: #ffffff;
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 37px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.popup-close-button-folder:hover {
  background: #a850a1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

button {
  cursor: pointer;
}

.bulk-action-bar button:disabled {
  background-color: #ccc;
}

.bulk-action-bar {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: sticky;
  top: 0;
  bottom: 700px;
  left: 80%;
  padding: 10px;
  z-index: 10;
}

.bulk-action-bar button {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.bulk-action-bar button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.bulk-action-button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 28px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.bulk-action-button span {
}

.export-button {
  background: rgba(139, 85, 221, 0.16);
}

.export-button .button-icon {
  width: 20px;
  height: 20px;
  color: var(--Secc, #8B55DD);
}

.delete-button {
  background: rgba(255, 174, 174, 0.63);
  border-radius: 28px;
  border: 1px solid var(--black_stroke, rgba(0, 0, 0, 0.10));
}

.delete-button .button-icon {
  width: 20px;
  height: 20px;
  color: rgba(226, 25, 25, 0.76);
}

.export-spna {
  color: var(--Secc, #8B55DD);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delete-spna {
  color: rgba(226, 25, 25, 0.76);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cancel-button {
  background: rgba(236, 236, 236, 0.60);
}

.cancel-button .button-icon {
  width: 20px;
  height: 20px;
}

.bulk-action-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
