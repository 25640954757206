.promptSearchBox {
    width: 330px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    display: flex;
    padding: 8px 20px;
    align-items: center;
    font-family: Outfit;
    margin: auto;
    margin-bottom: 20px;
}
.promptSearchBox input {
    width: 250px;
    height: 18px;
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    line-height: normal;
    border: none;
    outline: none;
}
.prompt-input-space {
    color: var(--Neutral-150, #71717A);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.prompt-modal-overlay-details {
    position: fixed;
    top: 52%;
    left: 58%;
    transform: translate(-50%, -50%); 
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;    
    width: 58.75vw; 
    max-width: 799px;
    height: 60.06vh; 
    max-height: 500px; 
    overflow: hidden;
    background-color: white;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 90px 417px 119px 0px rgba(0, 0, 0, 0.00), 
                58px 267px 109px 0px rgba(0, 0, 0, 0.01), 
                33px 150px 92px 0px rgba(0, 0, 0, 0.05), 
                14px 67px 68px 0px rgba(0, 0, 0, 0.09), 
                4px 17px 38px 0px rgba(0, 0, 0, 0.10);
    flex-shrink: 0;
    padding: 20px;
}

.linear-grad-btn {
    background: 
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(to bottom, rgba(201, 94, 190, 1), rgba(139, 85, 221, 1)) border-box !important;
    color: #313149;
    border: 2px solid transparent;
    border-radius: 30px;
}

.gray-brdr{
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.promptScrollSection {
    max-height: 320px;
    overflow-y: auto;
}

/* Scrollbar width and height */
.promptScrollSection::-webkit-scrollbar {
    width: 6px;  /* Width of vertical scrollbar */
    height: 181px; /* Height of horizontal scrollbar (if needed) */
}

/* Scrollbar track (the background) */
.promptScrollSection::-webkit-scrollbar-track {
    background: white;  /* Background color */
    border-radius: 16px;  /* Rounded corners */
    border: 1px solid rgba(0, 0, 0, 0.1);  /* Border color */
}

/* Scrollbar thumb (the draggable part) */
.promptScrollSection::-webkit-scrollbar-thumb {
    background: rgba(115, 96, 123, 0.6);  /* Background color */
    border-radius: 16px;  /* Rounded corners */
    border: 1px solid rgba(0, 0, 0, 0.1);  /* Border color */
}

/* Hover effect on scrollbar thumb */
.promptScrollSection::-webkit-scrollbar-thumb:hover {
    background: rgba(115, 96, 123, 0.8);  /* Slightly darker on hover */
}

