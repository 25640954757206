.page-container{
  font-family: "Inter", sans-serif;
  background-color: #fff;
}
.main{
  position: absolute;
}
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .reset-password-box {
    
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 450px;
  }
  
  .logo {
    width: 64.425px;
  height: 64.425px;
  flex-shrink: 0;
  }
  
  .reset-title {
    color: #171717;
    /* font-family: "Inter", sans-serif; */
    /* margin-bottom: 10px; */
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .subtitle {
    color: #52525B;
    margin-bottom: 30px;
    /* font-family: "Inter", sans-serif; */
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  
  .form-group {
    margin-bottom: 32px;
    text-align: left;
  }
  
  /* input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  } */
  .password-input{

    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;

    padding: 15px;
    font-size: 16px;
    width: 568px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
  }
  .input-hint {
    color:  #73607B;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .show-pass{
    left: 520px;
    right: 24px;
   }

  .reset-password-btn {
    background-color: #8B55DD;
    color: white;
    padding: 16px 224px;; /* Adjust padding to fit the text */
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    width: 568px; /* Ensure button width adapts to the content */
    white-space: nowrap; /* Prevent text from wrapping */
    transition: 0.7s;
  }
  
  .reset-password-btn:hover {
    background-color: #7a46d0;
  }
  .reset-password-btn:active{
    scale: 0.95;
  }
  .back-link {
    /* display: block; */
    margin-top: 20px;
    /* color: #7a7a7a; */
    font-family: "Inter", sans-serif;
    color: #52525B;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
  
  .back-link a {
    color: #8B55DD;
    text-decoration: none;
  }
  
  .error-message {
    color: red;
  }
  
 
  

  /* Successfully reset page css */

  /* Success page styles */
.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height for centered content */
  background-color: #f9f9f9; /* Light background for the success page */
  text-align: center; /* Center text */
}

.success-icon {
  margin-bottom: 20px; /* Space below the success icon */
}

.success-icon img {
  width: 60px; /* Adjust size of success icon */
  height: 60px;
}

.success-page h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333; /* Dark text color for visibility */
  margin-bottom: 10px;
}

.success-page p {
  font-size: 16px;
  color: #666; /* Lighter text color for the paragraph */
  margin-bottom: 30px;
}

.back-login-btn {
  padding: 16px 224px;
  gap: 10px;
  align-self: stretch;

  background-color: #8B55DD; /* Purple color for the button */
  color: white;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.back-login-btn:hover {
  background-color: #6b46c1; /* Darker purple on hover */
}
.back-login-btn:active{
  scale: 0.95;
}